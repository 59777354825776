// 投稿の中身はレイアウトなど含めここにまとめる
@charset "UTF-8";
@use "../mixin" as *;

.post-article {
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;

  .post-title {
    order: 3;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.6;
    width: 100%;
    max-width: 940px;
    padding: 0 20px;
    margin: 0 auto 50px;
  }

  .dates {
    order: 2;
    width: 100%;
    max-width: 940px;
    padding: 0 20px;
    margin: 0 auto 30px;
    font-size: 1.3rem;
    margin-bottom: 18px;

    span {
      padding: 2px 3px;
      display: inline-block;
    }

    .category-label {
      background: $label-color;
      color: $white;

      a {
        display: inline-block;
        color: $white;
        line-height: 1;
        padding: 2px 6px;
      }
    }
  }

  .post-main-image {
    order: 1;
    max-width: 1000px;
    margin: 0 auto 44px;

    img {
      margin: auto;
    }
  }

  .post-content {
    order: 4;
    width: 100%;
    max-width: 940px;
    padding: 0 20px;
    margin: auto;
  }

  .post-content {

    * {
      word-wrap: break-word;
    }

    p, figure, h2, h3, h4, h5, h6 {
      margin-bottom: 1.5em;
      line-height: 1.6;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h2 {
      font-size: 2.2rem;
      font-weight: 500;
      padding-bottom: 10px;
      border-bottom: 4px double $line-color;
    }

    h3 {
      font-size: 2rem;
      font-weight: 500;

      &:before {
        display: inline-block;
        content: '■';
        font-size: 1.6rem;
        margin-right: .5em;
      }
    }

    h4 {
      display: inline-block;
      font-size: 1.8rem;
      font-weight: 500;
      padding: 0 4px;
      border-bottom: 2px solid $line-color;
    }

    h5 {
      font-size: 1.8rem;
      font-weight: 500;
    }

    h6 {
      font-size: 1.6rem;
      font-weight: 500;
    }

    strong {
      font-weight: 500;
    }

    .has-text-align-center {
      text-align: center;
    }

    .wp-block-columns {
      display: flex;
      gap: 30px;

      @include sp {
        flex-direction: column;
      }

      .wp-block-column {

      }
    }

    .is-provider-youtube {
      .wp-block-embed__wrapper {
        width: 100%;
        height: 0;
        position: relative;
        padding-top: 56.25%;

        iframe {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }

}

.container-related-posts {
  .related-posts-title {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1em;
    text-align: center;
  }

  .archive-list {
    margin-bottom: 3em;
  }
}

#page-single {
  .news-wrap {
    max-width: 900px;
    margin: 0 auto 2em;
    padding: 0 20px;

    .news-list {
      width: 100%;
    }
  }
}