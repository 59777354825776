// single用
@charset "UTF-8";
@use "../mixin" as *;

#page-profile {
  .post-content {
    max-width: 1200px;
  }

  .wp-block-columns {
    display: flex;
    gap: 60px;

    @include sp {
      flex-direction: column;
    }
  }

  .wp-block-column {
    p {
      line-height: 1.8;
    }

    figure {
      @include sp {
        max-width: 80%;
        margin: auto;
      }
    }
  }
}

.post-article {
  h1.single-page-title {
    font-size: 4.8rem;
    text-align: center;
  }
}