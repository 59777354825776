// TOPページ用
@charset "UTF-8";
@use "../mixin" as *;

@keyframes grow {
  0% {
    height: 0;
    bottom: -10px;
  }

  50% {
    height: 50px;
    bottom: -60px;
  }

  100% {
    height: 0px;
    bottom: -60px;
  }
}

#page-home {
  .fixed-photo {
    position: fixed;
    width: 50%;
    height: 100vh;
    top: 0;
    left: 0;

    .c-photo {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 1s;
      background-color: $white;

      //&--fv {
        //background-image: url(/assets/dummy/kv.png);
        //background-repeat: no-repeat;
        //background-position: center top;
        //background-size: cover;
      //}

      //&--message {
        //background-image: url(/assets/dummy/message.jpg);
        //background-repeat: no-repeat;
        //background-position: center top;
        //background-size: cover;
      //}

      //&--profile {
        //background-image: url(/assets/dummy/profile.jpg);
        //background-repeat: no-repeat;
        //background-position: center top;
        //background-size: cover;
      //}

      &.show {
        opacity: 1;
      }
    }

    @media (max-width: 970px) {
      //position: relative;
      width: 100%;
      height: 70vh;

      .c-photo {
        height: 70vh;
        transition: all .1s;

        //&--fv {
          //background-image: url(/assets/dummy/kv.png);
          //background-position: left top;
          //background-size: auto 70vh;
        //}

        //&--message {
          //display: none;
        //}

        //&--profile {
          //display: none;
        //}
      }
    }
    &.scrolled {
      @media (max-width: 970px) {
        height: 100vh;

        .c-photo {
          height: 100vh;
        }
      }
    }

  }

  .flex-wrap {
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 2;

    .flex-right {
      width: 50%;

      @media (max-width: 970px) {
        width: 100%;
      }
    }

    .top-container {
      width: 100%;
      background: $gray-white;

      @media (max-width: 970px) {
        padding-top: 70vh;
        background: none;
      }

      .container-inner {
        width: 100%;
        background: $gray-white;
        padding: 80px 20px;
      }
    }

    .container-first-view {
      display: flex;
      align-items: center;
      position: relative;
      background: $gray-white;
      min-height: 100vh;

      @media (max-width: 970px) {
        min-height: 70vh;
        background: none;
      }

      @include sp {
        position: relative;
        padding-top: 70vh;
      }

      .container-inner {
        @media (max-width: 970px) {
          width: auto;
          position: absolute;
          top: 30%;
          right: 20px;
          background: none;
        }

        @include sp {
          position: relative;
          top: auto;
          right: auto;
          padding-top: 40px;
          width: 100%;
          background: $gray-white;
        }
      }

      h1 {
        margin: auto;
        img {
          margin: auto;
        }

        @media (max-width: 500px) {
          width: 60%;
        }
      }

      .c-news-wrap {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }

      .scroll {
        width: 100%;
        position: absolute;
        bottom: 80px;
        left: 0;
        text-align: center;

        &:before {
          display: block;
          width: 1px;
          height: 50px;
          background: $line-color;
          content: '';
          position: absolute;
          bottom: -60px;
          left: 50%;
          animation: grow 1.5s normal infinite ease-in-out;
        }

        @media (max-width: 970px) {
          bottom: 0;
        }

        @include sp {
          font-size: 12px;
          bottom: 40px;
        }
      }
    }

    .container-latest {
      @media (max-width: 970px) {
        padding-top: 0;
      }

      .container-inner {
        min-height: 100vh;
      }

      ul.post-list {
        max-width: 440px;
        margin: auto;

        > li {
          margin: 0 auto 80px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      ul.post-list {
        @include pc {
          > li {
            transform: scale(1);
            opacity: 1;
            transition: all .3s;
          }
      
          &:hover > li {
            opacity: 0.4;
            transform: scale(1);
      
            &:hover {
              opacity: 1;
              transform: scale(1.03);
            }
          }
        }
      }
    }

    .container-message {

      .container-inner {
        min-height: 100vh;
        @media (max-width: 970px) {

        }
      }

      .c-message {
        padding: 0 50px;

        @include sp {
          padding: 0;
        }

        p {
          line-height: 1.8;
          margin-bottom: .7em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .container-profile {
      position: relative;
      min-height: 100vh;
      background: $gray-white;

      @media (max-width: 970px) {
        min-height: auto;
        background: none;
      }

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: 970px) {
          position: relative;
          top: auto;
          left: auto;
          transform: translate(0, 0);
          text-align: center;
        }

        a {
          display: inline-block;
          border: 1px solid $line-color;
          padding: 12px 52px;
          font-size: 2rem;
          line-height: 1;
          background: $white;
          transition: all .3s;
          color: $color-def;

          .fa-solid {
            margin-left: 10px;
          }

          @include pc {
            &:hover {
              color: $white;
              background: $color-def;
            }
          }
        }
      }
    }
  }
}