@charset "UTF-8";
// 全体共通のものなどはここにまとめる
// ヘッダ・フッタなど
@use "mixin" as *;

//normalize的なもの
* {
  box-sizing: border-box;
}

a:link, a:visited, a:hover, a:active {
  color: $color-def;
  text-decoration: none;

  &[target="_blank"] {
    &:after {
      display: inline-block;
      margin-left: .5em;
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      content: "\f08e";
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

.sns-list {
  a[target="_blank"] {
    &:after {
      display: none;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

//font
html, body {
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  font-weight: 400;
  font-size: 10px;
  color: $color-def;
}

#main-container {
  font-size: 1.6rem;
}

#site-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  padding: 20px;

  @include sp {
    //width: 20%;
    left: auto;
    right: 0;
  }

  .header-logo {
    padding-right: 50px;
    max-width: 200px;
  }

  &.is-home {
    .header-logo {
      display: none;
    }
  }

  .menu {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
    
    a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      z-index: 200;

      span {
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        background: $line-color;
        transition: all .3s;

        &:first-child {
          top: 0;
          left: 0;
          transform: rotate(0deg);
          transition: top .3s .3s, transform .3s;
          transform-origin: center;
        }

        &:nth-child(2) {
          top: 9px;
          left: 0;
          opacity: 1;
          transition: opacity .1s .3s;
        }

        &:last-child {
          top: 18px;
          left: 0;
          transform: rotate(0deg);
          transition: top .3s .3s, transform .3s;
          transform-origin: center;
        }
      }

      @include pc {
        &:hover {
          span {
            background: $point-color;
          }
        }
      }
    }
  }

  &.opened {
    .menu {
      a {
        span {
          &:first-child {
            //animation: menuUpper 1s linear 0s normal forwards;
            transition: top .3s, transform .3s .3s;
            transform: rotate(45deg);
            top: 9px;
          }
    
          &:nth-child(2) {
            opacity: 0;
          }
    
          &:last-child {
            transition: top .3s, transform .3s .3s;
            top: 9px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  &.opened {

    .header-logo {
      display: none;

      @include sp {
        display: block;
      }
    }
  }
}

.global-menu {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  opacity: 0;
  transition: all .3s;
  display: flex;

  @include sp {
    flex-direction: column;
    background: $white;
  }

  .header-logo {
    width: 30%;
    padding: 20px;
    background: $gray-white;
    display: flex;
    align-items: center;
    justify-content: center;

    @include sp {
      //width: 100%;
      //justify-content: flex-start;
      display: none;
    }

    p {
      transition: all 1s .5s;
      transform: translateX(-30px);
      opacity: 0;

      @include sp {
        //width: 30%;
        //transform: translateX(10px);
      }
    }
  }

  .global-navi-wrap {
    width: 70%;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 80px;

    @include sp {
      padding: 100px 20px 0;
      width: 100%;
    }
  }

  .global-navi {
    font-size: 1.6rem;
    transform: translateX(30px);
    opacity: 0;
    transition: all 1s .5s;

    @include sp {
      transform: translateX(10px);
      width: 100%;
    }

    > li {
      font-size: 2.8rem;
      margin-bottom: .8em;

      @include sp {
        width: 100%;
        font-size: 1.8rem;
        border-bottom: 1px solid $line-color;
        padding-bottom: 14px;

        &:first-child {
          padding-bottom: 0;
        }
      }

      a {
        position: relative;
        transition: all .3s;

        &:after {
          display: block;
          content: '';
          width: 0%;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: -4px;
          background: $point-color;
          transition: all .3s;
        }

        @include pc {
          &:hover {
            color: $point-color;

            &:after {
              width: 100%;
            }
          }
        }
      }

      span {
        display: block;
        margin-bottom: .5em;
        font-size: 3rem;

        @include sp {
          font-size: 2.2rem;
        }
      }

      > ul {
        > li {
          font-size: 1.6rem;
          display: inline-block;
          vertical-align: top;
          margin-right: 1.5em;
          position: relative;

          @include sp {
            font-size: 1.5rem;
            border-bottom: 1px solid $line-color;
            display: block;
            margin-right: 0;

            &:last-child {
              border-bottom: none;
            }

            i {
              position: absolute;
              top: 50%;
              left: 20px;
              transform: translateY(-50%);
            }

            a {
              display: block;
              padding: 14px 10px 14px 40px;
            }
          }
        }
      }
    }
  }

  &.show {
    z-index: 199;
    opacity: 1;

    .header-logo {
      p {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .global-navi-wrap {
      .global-navi {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

.container-footer-contents {
  position: relative;
  z-index: 100;
  background: $gray-white;
  padding: 80px 40px;

  &.is-home {
    min-height: 100vh;
    background: $white;
  }

  @include sp {
    padding: 80px 20px;
  }

  .section-title {
    width: 100%;
  }

  .contents-navi {
    //flex-wrap: wrap;
    max-width: 1280px;
    margin: auto;

    .flex-wrap {
      display: flex;
      justify-content: center;

      @media (max-width: 970px) {
        flex-direction: column;
      }
    }

    .left-navi {
      padding: 0 75px;

      @media (max-width: 970px) {
        padding: 0;
        margin-bottom: 80px;
      }

      .category-list {
        display: flex;
        justify-content: center;
        gap: 86px;

        &.flex-wrap {
          flex-wrap: wrap;
        }

        @media (max-width: 970px) {
          gap: 40px;
          flex-wrap: wrap;
        }

        @include sp {
          flex-direction: column;
          gap: 0;
        }

        li {
          flex-basis: 198px;

          @include sp {
            flex-basis: auto;
            margin-bottom: 30px
          }


          a {
            display: block;
            font-size: 1.4rem;

            @include sp {
              display: flex;
              justify-content: flex-start;
            }

            .category-thumb {
              max-width: 150px;
              border-radius: 75px;
              overflow: hidden;
              margin: 0 auto 26px;

              @include sp {
                margin: 0 20px 0 0;
                max-width: 100px;
                max-height: 100px;
                flex-shrink: 0;
              }

              img {
                transform: scale(1);
                transition: all .5s;
              }
            }

            .category-name {
              font-size: 1.8rem;
              font-weight: 500;
              text-align: center;
              margin-bottom: 16px;

              @include sp {
                text-align: left;
              }
            }

            .category-desc {
              line-height: 1.5;
            }

            &:hover {
              .category-thumb {
                img {
                  transform: scale(1.2);
                }
              }

              .category-name {
                color: $point-color;
              }
            }
          }
        }
      }
    }

    .right-navi {
      border-left: 1px solid #d1d1d1;
      padding-left: 75px;
      max-width: 320px;

      @media (max-width: 970px) {
        border: none;
        padding: 0;
      }

      @include sp {
        max-width: none;
      }

      .banner-list {
        @media (max-width: 970px) {
          display: flex;
          justify-content: center;
          gap: 30px;
        }

        @include sp {
          flex-direction: column;
          align-items: center;
          gap: 0;
        }

        > li {
          flex-basis: 320px;
          margin-bottom: 35px;
          opacity: 1;
          transition: all .3s;

          @media (max-width: 970px) {
            max-width: 374px;
          }

          @include sp {
            flex-basis: auto;
          }

          &:last-child {
            margin-bottom: 0;
          }

          @include pc {
            &:hover {
              opacity: 0.8;
            }
          }

          .banner-image {
            margin-bottom: 10px;

            a {
              display: block;

              &[target="_blank"] {
                &:after {
                  display: none;
                }
              }
            }
          }

          .banner-title {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  .container-news {
    margin-top: 120px;
  }
}

#site-footer {
  background: $foote-base-color;
  position: relative;
  z-index: 100;
  color: $footer-text-color;
  width: 100%;
  padding: 54px 40px 90px;
  display: flex;
  justify-content: space-between;
  gap: 70px;

  @media (max-width: 970px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 700px) {
    gap: 0;
    padding: 54px 40px;
  }

  a {
    color: $footer-text-color;
  }

  .copyrights {
    p {
      margin-bottom: 20px;

      @media (max-width: 700px) {
        margin-bottom: 40px;
      }

      &:last-child {
        margin-bottom: 0;
        font-size: 1.2rem;
        letter-spacing: .05em;
      }
    }

    @media (max-width: 970px) {
      order: 2;
    }
  }

  .footer-links {

    @media (max-width: 970px) {
      order: 1;
      width: 100%;
    }

    .footer-link-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 6em;
      font-size: 1.4rem;
      margin-left: 1.5em;

      @media (max-width: 970px) {
        justify-content: center;
      }

      @media (max-width: 700px) {
        display: none;
      }

      > li {
        ul {
          > li {
            list-style: disc;
            margin-bottom: 1em;

            &:last-child {
              margin-bottom: 0;
            }

            @include pc {
              a:hover {
                color: $white;
                transition: all .3s;
              }
            }
          }
        }
      }
    }
  }

  .sns-follow {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 970px) {
      justify-content: center;
    }

    @media (max-width: 700px) {
      margin-bottom: 40px;
    }

    dt {
      margin-right: .8em;
    }

    dd {
      ul.sns-list {
        display: flex;
        align-items: center;

        li {
          font-size: 2.4rem;
          margin-right: .4em;

          &:last-child {
            margin-right: 0;
          }

          @media (max-width: 700px) {
            font-size: 3rem;
            margin-right: .6em;
          }
        }
      }
    }
  }
}

.to-pagetop {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 101;
  transition: all .1s;
  opacity: 1;

  &.hide {
    opacity: 0;
    z-index: -1;
  }

  a {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    font-size: 20px;
    color: $white;
    background: $color-def;
    border-radius: 4px;
    text-align: center;
    padding-top: 8px;

    &:before {
      content: '';
      display: block;
      width: 16px;
      height: 2px;
      border-radius: 1px;
      background: $white;
      top: 8px;
      left: 7px;
      position: absolute;
    }
  }
}

.page-2nd {
  padding: 150px 44px 100px;

  @include sp {
    padding: 100px 0;
  }
}