// 全ページ共通で使えるブロックパーツ類はここにまとめる
// サムネイルやサイドバーパーツなど
@charset "UTF-8";
@use "mixin" as *;

// ONLY HOW ON PC / SP
@include pc {
  .hide-on-pc{ display: none !important;}
}
@include sp {
  .hide-on-sp{ display: none !important;}
}

// SECTION TITLE
.section-title {
  margin-bottom: 60px;

  .en {
    font-size: 4.8rem;
    font-weight: 500;
    color: $point-color;
    display: block;
    position: relative;
    padding-bottom: 30px;
    text-align: center;

    @include sp {
      font-size: 3.4rem;
      padding-bottom: 20px;
    }

    &:after {
      content: '';
      display: block;
      width: 28px;
      height: 2px;
      background-color: $point-color;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    } 
  }

  .ja {
    margin-top: 12px;
    font-size: 2.2rem;
    display: block;
    text-align: center;

    @include sp {
      font-size: 1.6rem;
    }
  }
}

// POST LIST
ul.post-list {
  > li {
    max-width: 440px;

    .thumb {
      margin-bottom: 18px;

      a {
        display: block;
      }
    }

    .dates {
      font-size: 1.3rem;
      margin-bottom: 18px;

      span {
        display: inline-block;
      }

      .date {
        padding: 2px 3px;
      }

      .category-label {
        background: $label-color;

        a {
          display: inline-block;
          color: $white;
          line-height: 1;
          padding: 2px 6px;
        }
      }
    }

    h3 {
      font-size: 1.8rem;
      font-weight: 500;

      a {
      }
    }
  }
}

.archive-list {
  margin: auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;

  .post-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    > li {
      padding: 20px;
      width: calc(100%/3);
      opacity: 1;
      transition: all .3s;

      @include pc {
        &:hover {
          opacity: .8;
        }
      }

      @media (max-width: 970px) {
        width: calc(100%/2)
      }
    }
  }
}

// NEWS LIST
.news-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include sp {
    display: block;
  }

  .more {
    width: 100%;
  }

  .news-list {
    border-top: 1px dashed $line-color;

    > li {
      border-bottom: 1px dashed $line-color;

      &:last-child {
        border: none;
        font-size: 1.4rem;
        text-align: right;

        a {
          display: inline-block;
          opacity: 1;
          transition: all .3s;

          @include pc {
            &:hover {
              background: none;
              opacity: .7;
            }
          }
        }
      }

      div, a {
        display: block;
        padding: 20px 10px;
        background: $white;
        transition: all .3s;
      }

      a {
        &[target="_blank"] {
          &:after {
            display: none;
          }

          dl {
            dd {
              .news-title {
                span {
                  &:after {
                    display: inline-block;
                    margin-left: .5em;
                    font-family: "Font Awesome 6 Free";
                    font-weight: 900;
                    -webkit-font-smoothing: antialiased;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    line-height: 1;
                    content: "\f08e";
                  }
                }
              }
            }
          }
        }
      }

      @include pc {
        a:hover {
          background: $gray-white;
        }
      }

      dl {
        display: flex;
        font-size: 1.3rem;
        align-items: center;

        @include sp {
          flex-direction: column;
          justify-content: flex-start;
        }

        dt {
          width: 200px;
          flex-shrink: 0;
          display: flex;
          align-items: center;

          @include sp {
            justify-content: flex-start;
            width: 100%;
            margin-bottom: .5em;
          }

          .news-category {
            width: 100px;
            background: $label-color;
            color: $white;
            text-align: center;
            margin-left: 20px;
            padding: 4px 10px;
            flex-shrink: 0;
          }
        }

        dd {
          @include sp {
            width: 100%;
          }

          .news-title {
            font-size: 1.6rem;
            line-height: 1.5;
          }
        }
      }
    }
  }
}

.to-link {
  text-align: center;

  a {
    text-decoration: underline;
    font-weight: 500;
    transition: all .3s;

    @include pc {
      &:hover {
        color: $point-color;
      }
    }
  }
}
