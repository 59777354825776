// 変数系 / 関数系はここにまとめる

//ブレイクポイント
$sp: '768px'; //spのmax
$pc: '769px'; //pcのmin

// カラー設定
$color-def: #555; // ベースカラー
$point-color: #216174; // ページ大見出し
$label-color: #A09F9F; // カテゴリラベル
$line-color: #707070; // lineの色

$foote-base-color: #617E86; // Footerベースカラー
$footer-text-color: #F0F0F0; // フッターテキストカラー

$white: #fff; // 白
$gray-white: #F7F7F7; // グレイベタベースカラー

$gray: #e6e6e6;

//メディアクエリー記述モジュール
@mixin sp {
  @media screen and (max-width: ($sp)) {
    @content;
  }
}

@mixin pc {
  @media screen and (min-width: ($pc)) {
    @content;
  }
}

@function fit($value){
  @return calc($value / $sp) * 100 + vw;
}
