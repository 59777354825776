// 投稿の中身はレイアウトなど含めここにまとめる
@charset "UTF-8";
@use "../mixin" as *;

#page-archive {
  .page-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 120px;
    max-width: 1200px;
    margin: 0 auto 200px;

    @media (max-width: 970px) {
      gap: 60px;
    }

    @include sp {
      flex-direction: column;
      position: relative;
      margin-bottom: 60px;
    }

    .page-image {
      max-width: 520px;
      flex-shrink: 1;
      width: 44%;
      
      @include sp {
        width: 100%;
      }
    }

    .category-info {
      flex-shrink: 0;
      width: 44%;

      @include sp {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: 520px;
        padding: 20px;
        transform: translate(-50%, -50%);
        background: rgba(255, 255, 255, .6)
      }

      h1 {
        margin-bottom: 10px;
        font-size: 6.4rem;
        font-family: 'Sawarabi Mincho', serif;
        font-weight: normal;
        line-height: 1.5;

        @include sp {
          font-size: 4rem;
          text-align: center;
        }
      }

      p.en-title {
        color: $gray;
        margin-bottom: 44px;
        font-size: 4rem;
        font-weight: 700;

        @include sp {
          font-size: 2.4rem;
          text-align: center;
          color: $line-color;
        }
      }

      .description {
        font-size: 1.6rem;
        line-height: 1.5;

        @include sp {
          font-size: 1.4rem;
          text-align: center;
        }
      }
    }
  }

  .news-archive-header {
    margin-bottom: 100px;

    h1 {
      text-align: center;
      font-size: 4.8rem;

      @include sp {
        font-size: 3.2rem;
      }
    }
  }

  .news-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto 100px;

    h2 {
      font-size: 2.2rem;
      font-weight: 500;
      text-align: center;
      margin-bottom: 1em;
    }

    .news-wrap {
      padding: 0 20px;

      .news-list {
        width: 100%;
      }
    }
  }
}